.select-configure {
  position: absolute;
  box-shadow: 0px 10px 40px -10px #1e365280;
  border-radius: 10px;
  display: inline-block;
  z-index: 10;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  outline: none;
  white-space: initial;
}

.arrow {
  outline: none;
}

.arrow > * {
  pointer-events: none;
}

.table .row.header .cell-wrapper .select-configure {
	position: absolute;
	top: 50px;
	right: calc(100% - 22px);
	transform: translate(50%, 20px) scale(0.8);
}

.table .row.header .cell-wrapper .arrow:focus + .select-configure,
.table .row.header .cell-wrapper .select-configure:hover,
.table .row.header .cell-wrapper .select-configure:focus-within {
	transform: translate(50%, 0) scale(1);
}

.arrow:focus + .select-configure,
.pseudo-focus:focus + .select-configure,
.select-configure:hover,
.select-configure:focus-within,
.select-configure.show {
  opacity: 1;
  pointer-events: all;
}

.table .row.header .cell-wrapper .select-configure.align--left {
  left: 0;
  right: auto;
  transform: none;
  transform: translate(0%, 0) scale(0.8);
}

.select-configure.align--left .select-configure--arrow {
  left: 22px;
}

.table .row.header .cell-wrapper .arrow:focus + .select-configure.align--left,
.table .row.header .cell-wrapper .select-configure.align--left:hover,
.table .row.header .cell-wrapper .select-configure.align--left:focus-within {
  transform: translate(0%, 0) scale(1);
}

.table .cell[data-type="Tags"] .select-configure.align--left,
.table .cell[data-type="Select"] .select-configure.align--left {
  right: auto;
  left: 100%;
  transform: translateX(15px);
}

.table .cell[data-type="Tags"] .select-configure--arrow.left,
.table .cell[data-type="Select"] .select-configure--arrow.left {
  left: -6px;
  top: 27px;
}

.table .cell[data-type="Tags"] .select-configure--arrow.right,
.table .cell[data-type="Select"] .select-configure--arrow.right {
  top: 20px;
  right: -12px;
}

.cell--labels .select-configure.align--right .select-configure--arrow {

}

.select-configure--arrow {
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 12px;
  height: 12px;
  background: #fff;
}

.select-configure--arrow.bottom {
  top: auto;
  bottom: -6px;
}


.select-configure--arrow.left {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  right: -5px;
  left: auto;
}

.select-configure--options,
.select-configure--custom-fields {
  padding: 20px 25px;
}

.select-configure--options > div:first-of-type {
  padding-top: 0px;
}

.select-configure--options > div:last-of-type {
  padding-bottom: 0;
}

.select-configure--option {
  padding: 4px 0px;
  white-space: nowrap;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.1s ease;
}

.select-configure--option:hover,
.select-configure--option.selected {
  opacity: 1;
  font-weight: 500;
}

.select-configure h3 {
  font-size: 1.2em;
  margin: 0 0 0 0;
}

.select-configure hr {
  margin: 0;
  border: none;
  border-bottom: 1px solid var(--cell-border);
}

.select-configure .field [contenteditable] {
  width: 100%;
  height: 16px;
  display: inline-block;
  padding: 7px 9px 6px 9px;
}

.select-configure .field {
  display: inline-block;
  border: 1px solid var(--cell-border);
  width: calc(50% - 12px);
  margin: 0px 5px;
  border-radius: 5px;
}

.select-configure .field.textarea {
  width: calc(100% - 12px);
}

.select-configure .field.computed pre {
  padding: 0 10px;
  margin-top: 5px;
  font-family: menlo;
  font-size: 0.8em;
  width: calc(100% - 20px);
  height: auto;
  white-space: pre-wrap;
  line-height: 1.5;
  color: #1783bf;
}

.select-configure label {
  display: inline-block;
  margin-left: 9px;
  font-weight: 800;
  font-size: 0.8em;
  padding-top: 9px;
}

.select-configure .select-configure--custom-fields {
  min-width: 190px;
}

.select-configure .select-configure--custom-fields[data-name="Number"] {
  width: 200px;
}

.select-configure .select-configure--custom-fields[data-name="Computed"] {
  width: 300px;
}


.custom-fields--field-row {
  margin: 15px -5px 0px -5px;
}

.select-configure .MuiSvgIcon-root {
  height: 22px;
}

.select-configure .MuiSvgIcon-root + .label {
  display: inline-block;
  margin-top: 5px;
  margin-left: 5px;
  vertical-align: top;
}

.select-configure .alignment-group,
.select-configure .format-group {
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}

.select-configure .alignment-group .MuiSvgIcon-root.selected,
.select-configure .format-group .MuiSvgIcon-root.selected {
  background: var(--cell-border);
  border-color: #d4dfe6;
}

.select-configure .format-group .MuiSvgIcon-root {
  padding: 5px;
  display: inline-block;
  vertical-align: top;
  border: 1px solid var(--cell-border);
  height: 20px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.select-configure .alignment-group .MuiSvgIcon-root {
  padding: 5px;
  border: 1px solid var(--cell-border);
  margin-left: -1px;
  height: 20px;
  cursor: pointer;
}

.select-configure .alignment-group > svg:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.select-configure .alignment-group > svg:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.cell--labels .select-configure {
  right: 100%;
  top: 0%;
  transform: translate(-15px, 0);
}

.cell--labels .select-configure--options {
  max-height: 200px;
  overflow: auto;
}

.cell--labels .select-configure--arrow {
  top: 28px;
  right: -6px;
  left: auto;
}

.cell--labels .select-configure--add-tag-wrapper {
  padding: 16px 15px 13px 15px;
  white-space: nowrap;
}

.cell--labels .select-configure--add-tag-wrapper > * {
  display: inline-block;
}

.cell--labels .select-configure--add-tag-wrapper button {
  margin-left: 10px;
  border: 1px solid var(--cell-border);
  font-family: inherit;
  text-transform: uppercase;
  font-size: 0.8em;
  color: #1e365280;
  border-radius: 4px;
  padding: 6px 6px 3px 5px;
}

.cell--labels .select-configure--options {
  padding: 15px 15px 15px 15px;
}

.cell--labels .select-configure--options:empty {
  display: none;
  border-top: 1px solid var(--cell-border);
}

.expand .cell--labels .cell--pill {
  margin-top: 4px;
  margin-bottom: 4px;
}
